<template>
    <div class="up_content_inner up_add_branding">
        <div class="up_main_container_xs up_branding_basic_details">
            <div class="up_page_header">
                <h2 class="up_page_title">Branding</h2>
            </div>
            <div class="up_branding_details">
                <h3>Branding Details</h3>
                <div class="p-grid">
                    <div class="p-col-7">
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12">
                                <label for="websiteName">Website</label>
                                <Dropdown v-model="addEditBrandingData.websiteName" :options="initialBrandingData.website" :filter="true" optionLabel="siteName" placeholder="Select a Website" :disabled="initialBrandingData.editUUid !== null || initialBrandingData.newBranding">
                                    <template #value="slotProps" >
                                        <div class="p-dropdown-car-value" v-if="slotProps.value">
                                            <span>{{slotProps.value.siteName}} - {{slotProps.value.siteUrl}}</span>
                                        </div>
                                        <span v-else>
                                            {{slotProps.placeholder}}
                                        </span>
                                    </template>
                                    <template #option="slotProps">
                                        <div class="p-dropdown-car-option">
                                            <span>{{slotProps.option.siteName}} - {{slotProps.option.siteUrl}}</span>
                                        </div>
                                    </template>
                                </Dropdown>
                                <small class="p-invalid text-danger" v-if="addEditBrandingData.websiteNameEmpty && !initialBrandingData.newBranding">Please select Website</small>
                            </div>
                            <div class="p-field p-col-12">
                                <label for="websiteUrl">Logo</label>
                                <div class="up_image_upload">
                                    <FileUpload mode="basic" name="demo[]" url="./upload.php" chooseLabel="Choose File" accept="image/*" :maxFileSize="1000000" @select="onUpload($event)" />
                                </div>
                                <div v-if="initialBrandingData.logoFullUrl" class="up_image_preview">
                                    <img :src="initialBrandingData.logoFullUrl" alt="Logo">
                                </div>
                            </div>
                            <div class="p-field p-col-12">
                                <label for="websiteUrl">Logo URL</label>
                                <InputText id="websiteUrl" type="text" v-model="addEditBrandingData.logoUrl"  />
                            </div>
                            <div class="p-field p-col-12">
                                <label for="websiteUrl">Color</label>
                                <div class="up_color_picker">
                                    <ColorPicker v-model="addEditBrandingData.brandingColor" />
                                    {{addEditBrandingData.brandingColor}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-5">
                        <div class="up_branding_icon p-ml-4">
                            <h5>Icon</h5>
                            <div class="p-d-flex p-flex-wrap">
                                <div class="up_branding_icon_col" v-for="icon of initialBrandingData.icons" :key="icon.uuid">
                                    <div class="p-field-radiobutton">
                                        <RadioButton :id="icon.uuid" name="icon" :value="icon.iconPath" v-model="addEditBrandingData.iconName" />
                                        <label :for="icon.uuid">
                                            <img :src="iconUrl + icon.iconPath" :alt="icon.iconName">
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        
        <div class="up_additiona_detials">
            <div class="up_main_container_xs">
                <div class="p-grid p-mb-6 widget_preview">
                    <div class="p-col-7">
                        <div class="up_widget_features_box p-mb-3">
                            <h5>Widget Postion</h5>
                            <div class="p-formgroup-inline">
                                <div v-for="position of initialBrandingData.position" :key="position.value" class="p-field-radiobutton p-mr-2">
                                    <RadioButton :id="position.value" name="position" :value="position.value" v-model="addEditBrandingData.widgetPostion" />
                                    <label :for="position.value">{{position.name}}</label>
                                </div>
                            </div>
                        </div>
                        <div class="up_widget_features_box">
                            <h5>Mode</h5>
                            <div class="p-formgroup-inline">
                                <div v-for="mode of initialBrandingData.mode" :key="mode.value" class="p-field-radiobutton">
                                    <RadioButton :id="mode.value" name="mode" :value="mode.value" v-model="addEditBrandingData.widgetMode" />
                                    <label :for="mode.value">{{mode.name}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-5">
                        <div class="up_widget_features_preview">
                            <h5>Widget Preview</h5>
                            <div class="up_widget_position_box" :style="'background-image:url(' + imageUrlFrontEnd + 'dist/img/monitor.png)'" >
                                <div v-if="addEditBrandingData.widgetMode === 'full'" :class="['up_widget_full', 'up_widget_' + addEditBrandingData.widgetPostion]">
                                    <img :src="iconUrl + addEditBrandingData.iconName" alt="Icon">
                                </div>
                                <div v-else :class="['up_widget_compact', 'up_widget_' + addEditBrandingData.widgetPostion]">
                                    <img :src="imageUrlFrontEnd + 'dist/img/compact-mode.png'" alt="Icon">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="up_additional_service">
                    <div class="up_additional_service_title">
                        <h3>Additional service</h3>
                    </div>
                    <div class="up_siteTrack_option">
                        <h4>Enable Site Tracking</h4>
                        <div class="switch-box">
                            <label for="trackingEnable" class="switch switch-left-right" data-v-29537f2c="" @click="getSwitchValue($event, 'tracking')">
                                <input class="switch-input" tabindex="-1" type="checkbox" id="trackingEnable" :checked="addEditBrandingData.trackingEnable" name="gdprEnable" data-v-29537f2c="">
                                <span class="switch-label" data-on="Yes" data-off="No" data-v-29537f2c=""></span>
                                <span class="switch-handle" data-v-29537f2c=""></span>
                            </label>
                        </div>
                    </div>
                    <div class="up_gdpr_option">
                        <h4>Enable GDPR Compliance</h4>
                        <div class="switch-box">
                            <label for="gdprEnable" class="switch switch-left-right" data-v-29537f2c="" @click="getSwitchValue($event, 'gdpr')">
                                <input class="switch-input" tabindex="-1" type="checkbox" id="gdprEnable" :checked="addEditBrandingData.gdprEnable" name="gdprEnable" data-v-29537f2c="">
                                <span class="switch-label" data-on="Yes" data-off="No" data-v-29537f2c=""></span>
                                <span class="switch-handle" data-v-29537f2c=""></span>
                            </label>
                        </div>
                    </div>
                    
                    <div class="up_gdpr_lang p-mb-3">
                        <Dropdown v-if="addEditBrandingData.gdprEnable" v-model="addEditBrandingData.gdprLanguage" :options="language" :filter="true" optionLabel="languageName" optionValue="languageCode" placeholder="Select a Language" @change="gdprTrans(addEditBrandingData.gdprLanguage)">
                            <template #option="slotProps">
                                <div class="sup_country_flags p-d-flex p-align-center">
                                    <img :src="slotProps.option.flag" />
                                    <span class="p-ml-2">{{slotProps.option.languageName}}</span>
                                </div>
                            </template>
                        </Dropdown>
                    </div>

                    <div v-if="addEditBrandingData.gdprEnable" class="additional_service_editor">
                        <editor
                            api-key="no-api-key"
                            :init="{
                                menubar: false
                            }"
                            plugins="code"
                            toolbar="bold italic underline code"
                        v-model="addEditBrandingData.gdprContent[addEditBrandingData.gdprLanguage]"
                        />
                        
                    </div>
                </div>
                <div class="up_branding_submit p-d-flex">
                    <!-- <Button v-if="initialBrandingData.editUUid" type="submit" label="Submit" class="p-button-primary p-mr-2" @click="addBranding('edit')" />
                    <Button v-else type="submit" label="Submit" class="p-button-primary p-mr-2" @click="addBranding('add')" />
                    <Button type="button" label="Reset" class="p-button-white-shadow" @click="resetBrandingForm()" /> -->
                    <Button icon="pi pi-check" v-if="initialBrandingData.editUUid" type="submit" label="Submit" class="p-button-primary p-mr-2" @click="addBranding('edit')" />
                    <Button icon="pi pi-check" v-else type="submit" label="Submit" class="p-button-primary p-mr-2" @click="addBranding('add')" />
                    <Button icon="pi pi-refresh" v-if="!initialBrandingData.editUUid" type="button" label="Reset" class="p-button-white-shadow" @click="resetBrandingForm()" />
                </div>
                
            </div>
        </div>
        <!-- Add/Edit notification Toast Start -->
        <Toast />
        <!-- Add/Edit notification Toast End -->
    </div>
</template>

<script>
import { userPortalBrandingIcons, userPortalWebsite, userPortalBranding , allLanguageList, userPortalBrandingWebsiteList } from '../../../../config/appUrls';
import { apiKey, imageUrlFrontEnd, iconUrl } from "../../../../config/constant";
import customerLogout from '../../../../helper/userLogout/customerLogout';
import router from "../../../../router";
import axios from "axios";
import { useStore } from 'vuex';
import { useRoute } from "vue-router";
import { reactive, onBeforeMount, ref } from 'vue';
import Editor from '@tinymce/tinymce-vue';
import { useToast } from "primevue/usetoast";

export default {
    name: "AddEditBranding",
    components: {
        'editor': Editor
    },
    setup(){
        //Application store call
        const store = useStore();

        //Route call
        const route = useRoute();

        //Toast Message
        const toast = useToast();

        const language = ref();

        //Initial add/edit branding states
        const initialBrandingData = reactive({
            icons: '',
            website: '',
            loading: false,
            activityStatus : 'active',
            position: [
                {name: 'Right', value: 'right'},
                {name: 'Bottom Right', value: 'bottomRight'},
                {name: 'Bottom Center', value: 'bottomCenter'},
                {name: 'Bottom Left', value: 'bottomLeft'},
                {name: 'Left', value: 'left'},
                {name: 'Top Left', value: 'topLeft'},
                {name: 'Top Right', value: 'topRight'}
            ],
            mode: [
                {name: 'Full', value: 'full'},
                {name: 'Compact', value: 'compact'}
            ],
            logoFullUrl: null,
            editUUid: null,
            newBranding: false
        });

        //add/edit branding inputs modal and error handling states
        const addEditBrandingData = reactive({
            websiteName: '',
            websiteNameEmpty: false,
            logoName: '',
            iconName: '',
            logoUrl: '',
            brandingColor: '#2767da',
            widgetPostion: '',
            widgetMode: '',
            gdprEnable: false,
            trackingEnable: false,
            gdprLanguage: '',
            gdprContent: {}
        });

        onBeforeMount(async()=>{
            //Fetching icons
            await fetchBrandingIcons();
            //Fetching website names
            await fetchWebsite();
            //Fetching gdpr language list
            await gdprTrans(null);
            //Initial widget poistion set 
            addEditBrandingData.widgetPostion = initialBrandingData.position[0].value;
            //Initial widget mode set
            addEditBrandingData.widgetMode = initialBrandingData.mode[0].value;
            //Updating local state and UI based on props data
            // console.log(route.params)
            if(route.params.Website) {
                let newSite = Object.assign({},JSON.parse(route.params.Website));
                // console.log(newSite);
                initialBrandingData.website = [newSite.Website];
                console.log('if-block',initialBrandingData.website)
                addEditBrandingData.websiteName = newSite.Website;
                addEditBrandingData.iconName= initialBrandingData.icons[2].iconPath;
                addEditBrandingData.brandingColor= '#2767da';
                addEditBrandingData.widgetPostion= initialBrandingData.position[0].value;
                addEditBrandingData.widgetMode= initialBrandingData.mode[0].value;
                addEditBrandingData.trackingEnable= false;
                addEditBrandingData.gdprEnable= false;
                addEditBrandingData.logoUrl= '';
                addEditBrandingData.gdprLanguage= '';
                addEditBrandingData.gdprContent= {};
                addEditBrandingData.logoName = '',
                initialBrandingData.newBranding = true;
            }
            else if (route.params.brandingData !== undefined) {
                let data = JSON.parse(route.params.brandingData);
                addEditBrandingData.websiteName = data.data.Website;
                addEditBrandingData.iconName = data.data.icon;
                addEditBrandingData.brandingColor = data.data.color;
                addEditBrandingData.widgetPostion = data.data.widgetPosition;
                addEditBrandingData.widgetMode = data.data.widgetMode;
                addEditBrandingData.trackingEnable = data.data.enableSiteTracking;
                addEditBrandingData.gdprEnable = data.data.enableGdpr;
                addEditBrandingData.logoUrl = data.data.customlogoUrl;
                initialBrandingData.logoFullUrl = data.data.logo === "logo-white.png" ? iconUrl + "logo-white.png" : data.data.logo;
                initialBrandingData.editUUid = data.data.uuid;
                const gdprData = data.data.gdprText;
                //const gdprData = JSON.parse(data.data.gdprText);
                for (const key in gdprData) {
                    addEditBrandingData.gdprLanguage = key;
                }
                    addEditBrandingData.gdprContent = data.data.gdprText;
                    //addEditBrandingData.gdprContent = JSON.parse(data.data.gdprText);
            }
        });

        const resetBrandingForm = () =>{
            addEditBrandingData.websiteName= '';
            addEditBrandingData.iconName= initialBrandingData.icons[2].iconPath;
            addEditBrandingData.brandingColor= '#2767da';
            addEditBrandingData.widgetPostion= initialBrandingData.position[0].value;
            addEditBrandingData.widgetMode= initialBrandingData.mode[0].value;
            addEditBrandingData.trackingEnable= false;
            addEditBrandingData.gdprEnable= false;
            addEditBrandingData.logoUrl= '';
            addEditBrandingData.gdprLanguage= '';
            addEditBrandingData.gdprContent= {};
            addEditBrandingData.logoName = ''
        }

        /**
         * 
         * Fetching widget brand icons
         * 
         */
        const fetchBrandingIcons = async() =>{
            try{
                const response = await axios.get( userPortalBrandingIcons, {
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }, 
                });
                initialBrandingData.icons = response.data.data;
                initialBrandingData.total = response.data.data.total;
                addEditBrandingData.iconName = initialBrandingData.icons[2].iconPath;
            } catch(err){
                if(err.response.status === 403 || store.getters.adminAuthToken === '' ){
                    customerLogout();
                } else {
                    console.log('error', err);
                }
            }
        }

        /**
         * 
         * Fetching existing resgistered websites
         * 
         */
        const fetchWebsite = async() =>{
            try{
                if (window.location.pathname === '/customer/add-branding') {
                    const response = await axios.get( userPortalBrandingWebsiteList, {
                        headers: {
                            apiKey: apiKey,
                            token: store.getters.adminAuthToken
                        }, 
                    });
                    console.log(response.data.data);
                    initialBrandingData.website = response.data.data;
                } else {
                    const response = await axios.get( userPortalWebsite, {
                        headers: {
                            apiKey: apiKey,
                            token: store.getters.adminAuthToken
                        }, 
                    });
                    initialBrandingData.website = response.data.data.website;
                }
                
            } catch(err){
                if(err.response.status === 403 || store.getters.adminAuthToken === '' ){
                    customerLogout();
                } else {
                    console.log('error', err);
                }
            }
        }

        /**
         * Fetching widget languages
         */
        const gdprTrans = async(selection) =>{
            
            if (selection === null) {
                try{
                    const response = await axios.get( allLanguageList, {
                        headers: {
                            apiKey: apiKey,
                            token: store.getters.adminAuthToken
                        }, 
                    });
                    language.value = response.data.data.language;
                } catch(err){
                    if(err.response.status === 403 || store.getters.adminAuthToken === '' ){
                        customerLogout();
                    } else {
                        console.log('error', err);
                    }
                }
            } else {
                let gdprContent = {
                    en: "<h5>This Website Uses Cookies</h5> <p>We use cookies to provide personalized content to include advertising and to analyze our site traffic. We may also use collected information in marketing communication, customer supports, and analytics. Our usage of user data is compliant with all applicable legal requirements and industry standards. All parties we may share information with are obligated to maintain the confidentiality of personal information and use it solely for the purpose we disclose. Click here to <a href='https://truabilities.com/privacy-policy/' target='_blank' rel='noopener' data-cke-saved-href='https://truabilities.com/privacy-policy/'>learn more</a>.</p> <p>By continuing to our site you are consent to our use of cookies.</p>",
                    es: "<h5>Este sitio web utiliza cookies</h5><p>Usamos cookies para proporcionar contenido personalizado para incluir publicidad y analizar el tráfico de nuestro sitio. También podemos utilizar la información recopilada en comunicaciones de marketing, atención al cliente y análisis. Nuestro uso de los datos de los usuarios cumple con todos los requisitos legales aplicables y los estándares de la industria. Todas las partes con las que podemos compartir información están obligadas a mantener la confidencialidad de la información personal y utilizarla únicamente para el propósito que divulgamos. Haga clic aquí para <a href='https://truabilities.com/privacy-policy/' target='_blank' rel='noopener' data-cke-saved-href='https://truabilities.com/privacy-policy/'>aprender más</a>.</p> <p>Al continuar en nuestro sitio, acepta nuestro uso de cookies.</p>",
                    fr: "\r\nCe site Web peut stocker ou récupérer des données dans votre navigateur",
                    hi: "यह वेबसाइट आपके ब्राउज़र में डेटा को संग्रहीत या पुनर्प्राप्त कर सकती है",
                    ja: "このウェブサイトは、主にクッキーの形で、ブラウザにデータを保存または取得する場合があります。",
                    "zh-Hans": "该网站可能会在您的浏览器中存储或检索数据",
                    "zh-Hant": "該網站可能會在您的瀏覽器中存儲或檢索數據"
                }
                let obj = {};
                for (const key in gdprContent) {
                    if (key === selection) {
                        obj = {
                            [key]: gdprContent[key]
                        };
                    }
                }
                addEditBrandingData.gdprContent = obj;
            }
        }

        const onUpload = (event) => {
            addEditBrandingData.logoName = event.files[0];
        }

        /**
         * 
         * Branding add/edit data submit
         * Same function handling with "type" property value
         * formData json for api body
         * 
         */
        const addBranding = async(type) => {
            
            if (addEditBrandingData.websiteName === '') {
                addEditBrandingData.websiteNameEmpty = true;
            } else {
                addEditBrandingData.websiteNameEmpty = false;
            }

            if (!addEditBrandingData.websiteNameEmpty) {
                let formData = new FormData();
                formData.append("websiteUUID", addEditBrandingData.websiteName.uuid);
                formData.append("logo", addEditBrandingData.logoName);
                formData.append("logoUrl", addEditBrandingData.logoUrl === undefined ? '' : addEditBrandingData.logoUrl);
                formData.append("color", addEditBrandingData.brandingColor === '#2767da' ? '#2767da' : '#' + addEditBrandingData.brandingColor);
                formData.append("widgetPosition", addEditBrandingData.widgetPostion);
                formData.append("widgetMode", addEditBrandingData.widgetMode);
                formData.append("enableGDPR", addEditBrandingData.gdprEnable);
                console.log('data',addEditBrandingData.gdprContent)
                if (addEditBrandingData.gdprEnable) {
                    formData.append("gdprText", addEditBrandingData.gdprContent === "default" ? '' : JSON.stringify(addEditBrandingData.gdprContent));
                } else {
                    formData.append("gdprText", "")
                }
                formData.append("enableSiteTracking", addEditBrandingData.trackingEnable)
                formData.append("icon", addEditBrandingData.iconName);
                try{
                    let options;
                    if (type === 'add') {
                        options = {
                            method: 'post',
                            url: userPortalBranding,
                            data: formData,
                            headers: {"Content-Type": "multipart/form-data", 'apiKey': apiKey, 'token': store.getters.adminAuthToken},
                            transformRequest: [(data) => {
                                // transform the data
                                return data;
                            }]
                        };
                        
                    } else {
                        options = {
                            method: 'put',
                            params: {
                                uuid: initialBrandingData.editUUid
                            },
                            url: userPortalBranding,
                            data: formData,
                            headers: {"Content-Type": "multipart/form-data", 'apiKey': apiKey, 'token': store.getters.adminAuthToken},
                            transformRequest: [(data) => {
                                return data
                            }]
                        };
                    }
                    const res = await axios(options);
                    if (res.data.status === 200) {
                        toast.add({severity:'success', summary: 'Branding Added!', detail: "Branding added successfully."});
                        router.push({name: "CustomerBrandingList"});
                    }
                } catch(err) {
                    if (err.response.status === 400) {
                        toast.add({severity:'error', summary: 'Error!', detail: err.response.data.message});
                    }
                }
            }
            // if (!addEditBrandingData.websiteNameEmpty) {
            //     let formData = {};
            //     formData.websiteUUID =  addEditBrandingData.websiteName.uuid;
            //     formData.logo = addEditBrandingData.logoName;
            //     formData.logoUrl = addEditBrandingData.logoUrl === undefined ? '' : addEditBrandingData.logoUrl;
            //     formData.color = addEditBrandingData.brandingColor === '#2767da' ? '#2767da' : '#' + addEditBrandingData.brandingColor;
            //     formData.widgetPosition = addEditBrandingData.widgetPostion;
            //     formData.widgetMode = addEditBrandingData.widgetMode;
            //     formData.enableGDPR = addEditBrandingData.gdprEnable;
            //     if (addEditBrandingData.gdprEnable) {
            //         // formData.append("gdprText", addEditBrandingData.gdprContent === "default" ? '{}' : JSON.stringify(addEditBrandingData.gdprContent));
            //         formData.gdprText = addEditBrandingData.gdprContent === "default" ? {} : addEditBrandingData.gdprContent;
            //     } else {
            //         formData.gdprText = {}
            //     }
            //     formData.enableSiteTracking = addEditBrandingData.trackingEnable
            //     formData.icon = addEditBrandingData.iconName;
            //     try{
            //         let options;
            //         if (type === 'add') {
            //             options = {
            //                 method: 'post',
            //                 url: userPortalBranding,
            //                 data: formData,
            //                 headers: {"Content-Type": "multipart/form-data", 'apiKey': apiKey, 'token': store.getters.adminAuthToken},
            //                 transformRequest: [(data) => {
            //                     // transform the data
            //                     return data;
            //                 }]
            //             };
                        
            //         } else {
            //             options = {
            //                 method: 'put',
            //                 params: {
            //                     uuid: initialBrandingData.editUUid
            //                 },
            //                 url: userPortalBranding,
            //                 data: formData,
            //                 headers: {"Content-Type": "multipart/form-data", 'apiKey': apiKey, 'token': store.getters.adminAuthToken},
            //                 // transformRequest: [(data) => {
            //                 //     // transform the data
            //                 //     return data;
            //                 // }]
            //             };
            //         }
            //         const res = await axios(options);
            //         if (res.data.status === 200) {
            //             toast.add({severity:'success', summary: 'Branding Added!', detail: "Branding added successfully."});
            //             router.push({name: "CustomerBrandingList"});
            //         }
            //     } catch(err) {
            //         if (err.response.status === 400) {
            //             toast.add({severity:'error', summary: 'Error!', detail: err.response.data.message});
            //         }
            //     }
            // }
        }

        /**
         * 
         * Switch button and update state
         * 
         */
        const getSwitchValue = (event, load) =>{
            if (event.target.checked !== undefined) {
                if (load === 'gdpr') {
                    addEditBrandingData.gdprEnable = event.target.checked === true ? true : false;
                } else {
                    addEditBrandingData.trackingEnable = event.target.checked === true ? true : false;
                }
            }
        }

        return {
            initialBrandingData,
            addEditBrandingData,
            imageUrlFrontEnd,
            iconUrl,
            language,
            addBranding,
            getSwitchValue,
            onUpload,
            gdprTrans,
            resetBrandingForm
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/style/component/userportal/pages/ManageBranding/AddEditBranding';

.tox-notification--warning, .tox-statusbar__branding {
    display: none !important;
}
</style>